import React from 'react';
import {NotificationStatsT} from '../../../../types/dashboard';
import styled from 'styled-components';

type PropsT = {
  stats?: NotificationStatsT;
  loading?: boolean;
};

export const Stats: React.FC<PropsT> = ({stats, loading}) => {
  const smsGroups = stats?.smsCount || 0;
  const emailGroups = stats?.emailCount || 0;

  const failedEmails = (stats?.email?.totalSent || 0) - (stats?.email?.totalDelivered || 0);
  const failedSms = (stats?.sms?.totalSent || 0) - (stats?.sms?.totalDelivered || 0);
  if (loading) return <div>Stats Loading...</div>;
  return (
    <CardsWrapper>
      <Card>
        <CardWrapper>
          <Col>
            <span>Notifications</span>
            <span>{smsGroups + emailGroups}</span>
          </Col>
          <Rows>
            <Row>
              <span>SMS</span>
              <span>{smsGroups}</span>
            </Row>
            <Row>
              <span>Email</span>
              <span>{emailGroups}</span>
            </Row>
          </Rows>
        </CardWrapper>
      </Card>
      <Card>
        <CardWrapper>
          <Col>
            <span>SMS</span>
            <span>{smsGroups}</span>
          </Col>
          <Rows>
            <Row>
              <span>Delivered</span>
              <span>{stats?.sms?.totalDelivered || '-'}</span>
            </Row>
            <Row>
              <span>Failed</span>
              <span>{failedSms || '0'}</span>
            </Row>
          </Rows>
        </CardWrapper>
      </Card>
      <Card>
        <CardWrapper>
          <Col>
            <span>Email</span>
            <span>{emailGroups}</span>
          </Col>
          <Rows>
            <Row>
              <span>Delivered</span>
              <span>{stats?.email?.totalDelivered || '-'}</span>
            </Row>
            <Row>
              <span>Failed</span>
              <span>{failedEmails || '0'}</span>
            </Row>
          </Rows>
        </CardWrapper>
      </Card>
    </CardsWrapper>
  );
};

const CardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 14px;
`;

const Card = styled.div`
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid ${({theme}) => theme.palette.border.lightGray};
  background-color: ${({theme}) => theme.palette.common.white};
`;

const CardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 36px;
`;

const Col = styled.div`
  display: flex;
  gap: 2px;
  flex-direction: column;

  span {
    &:first-of-type {
      font-size: 12px;
      color: ${({theme}) => theme.palette.common.darkGray};
    }
    &:last-of-type {
      font-size: 20px;
      font-weight: bold;
      color: ${({theme}) => theme.palette.common.black};
    }
  }
`;

const Rows = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 4px;

  span {
    &:first-of-type {
      font-size: 12px;
      color: ${({theme}) => theme.palette.common.darkGray};
    }
    &:last-of-type {
      font-size: 12px;
      color: ${({theme}) => theme.palette.common.black};
    }
  }
`;
