import {Modal} from 'antd';
import React from 'react';
import styled from 'styled-components';
import {LogT} from '../../types/messages';
import {Button, ButtonTypes} from '../../ui-kit/Button';
import {LogsFilter} from '../../components/Message/ui/desktop/LogsFilter';
import {useFilterLogs} from '../../hooks/templates';
import {LogsTable} from '../../components/Message/ui/desktop/LogsTable';

type PropsT = {
  modal: boolean;
  setModal: (value: boolean) => void;
  data?: LogT[];
  onDownload?: () => Promise<void>;
  loading?: boolean;
};

export const LogsModal: React.FC<PropsT> = ({modal, setModal, data, onDownload, loading}) => {
  const {filteredLogs, ...filter} = useFilterLogs({logs: data});
  const hideModal = () => setModal(false);

  const Footer = () => (
    <ModalFooter>
      <div>
        <p>Results: {filteredLogs?.length || 0}</p>
      </div>
      <Button variant={ButtonTypes.secondary} onClick={onDownload} loading={loading}>
        Download CSV
      </Button>
    </ModalFooter>
  );

  const Title = () => {
    return (
      <TitleBlock>
        <p>Delivery Log</p>
        <FilterWrapper>
          <p>
            Here&apos;s the list of people who received the notification. There&apos;s a limit of 100 people in the list
            below. Download CSV to see the full list.
          </p>
          <LogsFilter {...filter} />
        </FilterWrapper>
      </TitleBlock>
    );
  };

  return (
    <Modal title={<Title />} open={modal} onCancel={hideModal} closable footer={<Footer />} className="logs-modal">
      <LogsTable data={filteredLogs} />
    </Modal>
  );
};

const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 46px;

  p {
    font-size: 12px;
    line-height: 16px;
    margin: 2px 0;
    padding: 0;
    text-align: left;
  }
`;

const TitleBlock = styled.div`
  p {
    margin: 0;
    padding: 0;
  }
  & > p {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
  }
`;

const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  p {
    margin-top: 6px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: ${({theme}) => theme.palette.common.darkGray};
    max-width: 70%;
  }
`;
