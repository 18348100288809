import {Modal} from 'antd';
import React from 'react';
import styled from 'styled-components';
import {RecipientsTable} from '../../components/Message/ui/desktop/RecipientsTable';
import {RecipientT} from '../../types/messages';
import {CSVButton} from '../CSV';
import {recipientsToCSV} from '../../helpers/csv';

type PropsT = {
  modal: boolean;
  setModal: (value: boolean) => void;
  recipients?: RecipientT[];
};

const Title = () => {
  return (
    <TitleBlock>
      <p>View Recipients</p>
      <p>
        Here’s the list of people who will be receiving this message based on the audience settings. There’s a limit of
        100 people in the list below. Download CSV to see the full list.
      </p>
    </TitleBlock>
  );
};

export const MessageRecipients: React.FC<PropsT> = ({modal, setModal, recipients}) => {
  const hideModal = () => setModal(false);
  const guests = recipients?.length || 0;
  const tickets = recipients?.reduce((p, n) => p + (Number(n?.totalTickets || 0) || 0), 0);
  const csvData = recipientsToCSV(recipients);
  const Footer = () => (
    <ModalFooter>
      <div>
        <p>Total Guests: {guests}</p>
        <p>Total Tickets: {tickets}</p>
      </div>
      <CSVButton data={csvData} filename={`Recipients_${new Date().getTime()}`} />
    </ModalFooter>
  );

  return (
    <Modal
      title={<Title />}
      open={modal}
      onCancel={hideModal}
      closable
      footer={<Footer />}
      className="recipients-modal">
      <RecipientsTable recipients={recipients} />
    </Modal>
  );
};

const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 46px;

  p {
    font-size: 12px;
    line-height: 16px;
    margin: 2px 0;
    padding: 0;
    text-align: left;
  }
`;

const TitleBlock = styled.div`
  p {
    margin: 0;
    padding: 0;
    &:first-of-type {
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
    }
    &:last-of-type {
      margin-top: 6px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: ${({theme}) => theme.palette.common.darkGray};
    }
  }
`;
