import {ChangeEvent, useState} from 'react';
import {checkOrderUrl, sendTestNotification} from '../queries/notifications';
import {formatTestNotification} from '../helpers/messages';
import {TestMessageFields} from '../types/messages';

type PropsT = {
  brand?: string;
  subject?: string;
  message?: string;
  notificationMode?: string[];
};

export const useTestMessage = ({brand, subject, message}: PropsT) => {
  const [order, setOrder] = useState<string | undefined>();
  const [orderResult, setOrderResult] = useState<'success' | 'failed' | 'none'>('none');
  const [orderLoading, setOrderLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [values, setValues] = useState<TestMessageFields | undefined>();

  const onSubmit = async (values: any) => {
    const formattedValues = formatTestNotification({
      email: values?.email,
      phoneNumber: values?.phone?.replaceAll(' ', ''),
      ticketureOrderId: order,
      subject,
      message,
    });
    setValues(formattedValues);

    try {
      setSuccess(false);
      setLoading(true);
      const res = await sendTestNotification(formattedValues);
      console.log('res', res);
      setSuccess(true);
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };
  const onOrderChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOrderResult('none');
    setOrder(e.target.value);
  };

  const onCheckOrder = async () => {
    try {
      if (!order || !brand) return;
      setOrderLoading(true);
      setOrderResult('none');
      await checkOrderUrl(order, brand);
      setOrderResult('success');
    } catch (error) {
      setOrderResult('failed');
      console.error('order validation:', error);
    } finally {
      setOrderLoading(false);
    }
  };

  const isDisabled = !order || orderResult !== 'success';

  return {
    onSubmit,
    onCheckOrder,
    orderResult,
    order,
    onOrderChange,
    orderLoading,
    isDisabled,
    loading,
    success,
    values,
  };
};
