import React from 'react';
import {CreateMessageDesktop} from '../../components/Message/ui/desktop';
import {useCreateNotification} from '../../hooks/messages';
import {useGetListTemplatesTable, useGetTemplate} from '../../hooks/templates';
// import {isMobile} from '../../ui-kit/theme/breakpoints';
// import {CreateMessageMobile} from '../../components/Message/ui/mobile';
import {useNavigate} from 'react-router-dom';
import {route} from '../../constants/routes';

export const CreateMessage = () => {
  const createData = useCreateNotification();
  const {templates} = useGetListTemplatesTable();
  const {template, getOne} = useGetTemplate();
  const navigate = useNavigate();

  const goDashboard = () => navigate(route.dashboard.path);

  return (
    <CreateMessageDesktop
      mode={'create'}
      formData={createData}
      templates={templates}
      getTemplate={getOne}
      selectedTemplate={template}
      goDashboard={goDashboard}
    />
  );
};
