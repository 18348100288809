import React from 'react';
import {MessageTypes} from '../types/messages';
import {ButtonTypes} from '../ui-kit/Button';
import moment from 'moment';

export type TableColumnT = {
  title: string | React.ReactNode;
  dataIndex: string;
  key: string;
  render?: (a: any) => React.ReactNode;
};

export const createColumns = (columns: {key: string; label: string}[]): TableColumnT[] => {
  return columns?.map((el) => ({title: el.label, dataIndex: el.key, key: el.key}));
};

export const toTableDateFormat = (value: string) => {
  return moment.utc(value).format('MMM. DD, yyyy h:mm a');
};

export enum DashboardTableFields {
  id = 'id',
  name = 'name',
  messageType = 'messageType',
  notificationType = 'notificationType',
  location = 'location',
  dateSent = 'dateSent',
  dateScheduled = 'dateScheduled',
  scheduledOn = 'scheduledOn',
  status = 'status',
  actions = 'actions',
}

export const DashboardTableColumnsTitles = {
  id: 'ID',
  name: 'Name',
  messageType: 'Message Type',
  notificationType: 'Notification Type',
  location: 'Location',
  dateSent: 'Date Sent',
  dateScheduled: 'Scheduled',
  scheduledOn: 'Event Start Time',
  status: 'Status',
  actions: '',
};

export const DashboardCodeMap = {
  [MessageTypes.scheduled]: ButtonTypes.quinary,
  [MessageTypes.sent]: ButtonTypes.secondary,
  [MessageTypes.instant]: ButtonTypes.secondary,
};

export enum GroupDetailTableFields {
  code = 'code',
  email = 'email',
  actions = 'actions',
}

export const GroupDetailTableColumnsTitles = {
  code: 'Promo Codes',
  actions: 'Action',
};

export enum ManagementTableFields {
  fullName = 'fullName',
  email = 'email',
  role = 'role',
  department = 'department',
  access = 'access',
  actions = 'actions',
}

export const ManagementTableColumnsTitles = {
  fullName: 'Full Name',
  email: 'Email Address',
  role: 'Role',
  department: 'Department',
  access: 'Portal Access',
  actions: 'Actions',
};

export enum RoleConfigsTableFields {
  name = 'name',
  view = 'view',
  manageGroup = 'manageGroup',
  manageDistr = 'manageDistr',
  actions = 'actions',
}

export const RoleConfigsTableColumnsTitles = {
  role: 'Roles',
  view: 'View-only',
  manageGroup: 'Manage Group',
  manageDistr: 'Manage Distribution',
  action: 'Action',
};

export enum ConfigsTableFields {
  types = 'types',
  actions = 'actions',
}

export const ConfigsTableColumnsTitles = {
  types: 'Ticket types',
  action: 'Actions',
};

//------- DEPARTMENT ---------
export enum DepartmentTableFields {
  name = 'name',
  actions = 'actions',
}

export const DepartmentTableColumnsTitles = {
  types: 'Types',
  action: 'Actions',
};

//------- PERMISSIONS ---------
export enum PermissionTableFields {
  name = 'name',
  description = 'description',
  actions = 'actions',
}

export const PermissionTableColumnsTitles = {
  name: 'Name',
  description: 'Description',
  action: 'Actions',
};
