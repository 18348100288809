import React from 'react';
import {DashboardPageProps} from '../../types';
import {CreateButton, DashboardHeaderWrapper, ManageButton, StyledTabs} from './styles';
import {DashboardTable} from './Table';
import {route} from '../../../../constants/routes';
import {Stats} from './Stats';
import {Tabs} from 'antd';

// const pageSizeOptions = [10, 25, 50, 100];

export const DesktopDashboard: React.FC<DashboardPageProps> = ({
  goEdit,
  goView,
  onDelete,
  // pagination,
  searchNotifications,
  // isSearching,
  loading,
  notifications,
  stats,
  statsLoading,
  currentTab,
  changeTab,
}) => {
  return (
    <>
      <DashboardHeaderWrapper>
        <Stats stats={stats} loading={statsLoading} />
        <div>
          <ManageButton to={route.manageTemplates.path}>Manage Templates</ManageButton>
          <CreateButton to={route.createNewMessage.path}>Create New Message</CreateButton>
        </div>
      </DashboardHeaderWrapper>
      <StyledTabs>
        <Tabs activeKey={currentTab} onChange={changeTab}>
          <Tabs.TabPane tab="All Groups" key="all">
            <DashboardTable
              data={notifications}
              searchNotifications={searchNotifications}
              goEdit={goEdit}
              goView={goView}
              onDelete={onDelete}
              loading={loading}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Enchant" key="enchant">
            <DashboardTable
              data={notifications}
              searchNotifications={searchNotifications}
              goEdit={goEdit}
              goView={goView}
              onDelete={onDelete}
              loading={loading}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Classic" key="coke">
            <DashboardTable
              data={notifications}
              searchNotifications={searchNotifications}
              goEdit={goEdit}
              goView={goView}
              onDelete={onDelete}
              loading={loading}
            />
          </Tabs.TabPane>
        </Tabs>
      </StyledTabs>
      {/* <PaginationWrapper>
        {!isSearching && (
          <Pagination
            showSizeChanger
            onShowSizeChange={pagination.changeLimit}
            current={pagination.pageNumber}
            onChange={pagination.changePage}
            total={pagination.total}
            pageSizeOptions={pageSizeOptions}
          />
        )}
      </PaginationWrapper> */}
    </>
  );
};
