import {NotificationQueryList, SellersQueryList} from '../constants/api';
import {TestMessageFields} from '../types/messages';
import {deleteQuery, getQuery, postQuery, putQuery} from './hooks';
import {
  createNotificationReq,
  createTemplateReq,
  deleteNotificationReq,
  deleteTemplateReq,
  findAudienceReq,
  getNotificationReq,
  getTemplateReq,
  updateNotificationReq,
  updateTemplateReq,
} from './types';

export const getListTemplates = async () => await getQuery(NotificationQueryList.getListTemplates());

export const createTemplate = async ({body}: createTemplateReq) =>
  await postQuery(NotificationQueryList.createTemplate()).send(body);

export const getTemplate = async ({templateId}: getTemplateReq) =>
  await getQuery(NotificationQueryList.getTemplate(templateId));

export const updateTemplate = async ({templateId, body}: updateTemplateReq) =>
  await putQuery(NotificationQueryList.updateTemplate(templateId)).send(body);

export const deleteTemplate = async ({templateId}: deleteTemplateReq) =>
  await deleteQuery(NotificationQueryList.deleteTemplate(templateId));

export const findAudience = async ({body}: findAudienceReq) =>
  await postQuery(NotificationQueryList.findAudience()).send(body);

export const createNotification = async ({body}: createNotificationReq) =>
  await postQuery(NotificationQueryList.createNotification()).send(body);

export const getListNotifications = async () => await getQuery(NotificationQueryList.getListNotifications());

export const getNotification = async ({notificationId}: getNotificationReq) =>
  await getQuery(NotificationQueryList.getNotification(notificationId));

export const updateNotification = async ({notificationId, body}: updateNotificationReq) =>
  await putQuery(NotificationQueryList.updateNotification(notificationId)).send(body);

export const deleteNotification = async ({notificationId}: deleteNotificationReq) =>
  await deleteQuery(NotificationQueryList.deleteNotification(notificationId));

export const getSellers = async (brand?: string) => await getQuery(SellersQueryList.getSellers(brand));

export const getStats = async () => await getQuery(NotificationQueryList.getNotificationStats());
export const getBrands = async () => await getQuery(NotificationQueryList.getBrands());
export const checkOrderUrl = async (url: string, brand?: string) =>
  await getQuery(NotificationQueryList.checkUrl(url, brand));

export const sendTestNotification = async (body: TestMessageFields) =>
  await postQuery(NotificationQueryList.sendTestNotification()).send(body);

export const getNotificationLogs = async (id: string) => await getQuery(NotificationQueryList.getNotificationLogs(id));
export const getNotificationLogsCSV = async (id: string) =>
  await getQuery(NotificationQueryList.getNotificationsLogsCSV(id)).responseType('blob');
