export enum AuthQueryList {
  logout = '/v1/auth/logout',
  authorize = '/v1/auth/authorize',
}

export const SettingsQueries = {
  getPermissions: () => `/v1/permission`,
  createPermission: () => `/v1/permission`,
  updatePermission: (permissionId: string) => `/v1/permission/${permissionId}`,
  deletePermission: (permissionId: string) => `/v1/permission/${permissionId}`,
  getRoles: () => `/v1/role`,
  createRole: () => `/v1/role`,
  updateRole: (roleId: string) => `/v1/role/${roleId}`,
  deleteRole: (roleId: string) => `/v1/role/${roleId}`,
  assignPermissions: (roleId: string) => `/v1/role/${roleId}/assignPermissions`,
  getDepartments: () => `/v1/department`,
  createDepartment: () => `/v1/department`,
  updateDepartment: (departmentId: string) => `/v1/department/${departmentId}`,
  deleteDepartment: (departmentId: string) => `/v1/department/${departmentId}`,
};

export const UsersQueries = {
  getUsers: () => `/v1/user`,
  getUser: (userId: string) => `/v1/user/${userId}`,
  updateUser: (userId: string) => `/v1/user/${userId}`,
  deleteUser: (userId: string) => `/v1/user/${userId}`,
  inviteUser: () => `/v1/user`,
};

export const NotificationQueryList = {
  getListTemplates: () => `/v1/notificationTemplate`,
  createTemplate: () => `/v1/notificationTemplate`,
  getTemplate: (templateId: string) => `/v1/notificationTemplate/${templateId}`,
  updateTemplate: (templateId: string) => `/v1/notificationTemplate/${templateId}`,
  deleteTemplate: (templateId: string) => `/v1/notificationTemplate/${templateId}`,
  findAudience: () => `/v1/updateTicketAudience`,
  getAudience: () => `/v1/ticketAudience`,
  sendNotificationToAudience: () => `/v1/audience/send`,
  createNotification: () => `/v1/notification`,
  getListNotifications: () => `/v1/notification?sortBy=id&sortOrder=DESC`,
  getNotification: (notificationId: string) => `/v1/notification/${notificationId}`,
  updateNotification: (notificationId: string) => `/v1/notification/${notificationId}`,
  deleteNotification: (notificationId: string) => `/v1/notification/${notificationId}`,

  //new
  getNotificationStats: () => `/v1/notification/stats`,
  getBrands: () => `/v1/brands`,
  checkUrl: (url: string, brand?: string) =>
    `/v1/ticketGroup/orderStatus?ticketureOrderId=${url}${brand ? `&brand=${brand}` : ''}`,

  sendTestNotification: () => `/v1/sendTestNotification`,
  getNotificationLogs: (id: string) => `/v1/notification/${id}/notificationLogs`,
  getNotificationsLogsCSV: (id: string) => `/v1/notification/${id}/notificationLogs/export`,
};

export const SellersQueryList = {
  getSellers: (brand?: string) => `/v1/sellers?${brand ? `brand=${brand}` : ''}`,
};
