import {useEffect, useState} from 'react';
import {getStats} from '../queries/notifications';
import {NotificationStatsT} from '../types/dashboard';
import {NotificationsT} from '../queries/types';

export const useNotificationStats = (data?: NotificationsT[]) => {
  const [statsLoading, setStatsLoading] = useState(true);
  const [stats, setStats] = useState<NotificationStatsT | undefined>();

  const fetchStats = async () => {
    try {
      setStatsLoading(true);
      const res = await getStats();
      const stats = res?.body as NotificationStatsT;
      setStats(stats);
    } catch (error) {
    } finally {
      setStatsLoading(false);
    }
  };

  useEffect(() => {
    fetchStats();
  }, []);

  const smsCount =
    data?.filter((el) => el?.notificationMode?.some((el: string) => el?.toLowerCase() === 'sms'))?.length || 0;
  const emailCount =
    data?.filter((el) => el?.notificationMode?.some((el: string) => el?.toLowerCase() === 'email'))?.length || 0;

  return {stats: {...stats, emailCount, smsCount}, statsLoading};
};
