import React from 'react';
import {useNavigate} from 'react-router-dom';
import {DashboardPage} from '../../components/Dashboard/ui';
import {route} from '../../constants/routes';
import {useDeleteNotification, useGetListNotificationsTable} from '../../hooks/messages';
import {useNotificationStats} from '../../hooks/dashboard';

export const Dashboard = () => {
  const navigate = useNavigate();
  const {notifications, loading, refetch, isSearching, searchNotifications, pagination, changeTab, currentTab, data} =
    useGetListNotificationsTable();
  const {stats, statsLoading} = useNotificationStats(data);

  const goEdit = (id: string) => {
    navigate(route.editMessage.get({id}));
  };
  const goView = (id: string) => {
    navigate(route.viewMessage.get({id}));
  };

  const {onDelete} = useDeleteNotification(refetch);

  return (
    <DashboardPage
      notifications={notifications}
      goEdit={goEdit}
      goView={goView}
      onDelete={onDelete}
      loading={loading}
      isSearching={isSearching}
      pagination={pagination}
      searchNotifications={searchNotifications}
      stats={stats}
      statsLoading={statsLoading}
      changeTab={changeTab}
      currentTab={currentTab}
    />
  );
};
