import React from 'react';
import {
  PreviewModalTitle,
  TitleDescription,
  OptionRow,
  OptionRowTitle,
  OptionRowValue,
  MessageWrapper,
  SubjectWrapper,
} from './modalStyles';
import styled from 'styled-components';
import {Button, ButtonTypes} from '../../../../ui-kit/Button';
import {LocationOptions, LogT, NotificationDetailedT, RecipientT} from '../../../../types/messages';
import {formatEventDate} from '../../../../helpers/messages';
import {Media} from '../../../../ui-kit/theme/breakpoints';
import {NotificationStats} from './Stats';
import {Loader} from '../../../../ui-kit/Loader';

type PropsT = {
  notification?: NotificationDetailedT;
  locations?: LocationOptions[];
  recipients?: RecipientT[];
  logs?: LogT[];
  loading?: boolean;
  viewLogs?: () => void;
};

export const ViewNotification: React.FC<PropsT> = ({notification, viewLogs, locations, recipients, loading, logs}) => {
  const guests = recipients?.length || 0;
  const tickets = recipients?.reduce((p, n) => p + (Number(n?.totalTickets || 0) || 0), 0);
  if (loading)
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  return (
    <PageWrapper>
      <Wrapper>
        <Head>
          <TitleBlock>
            <PreviewModalTitle>{notification?.name}</PreviewModalTitle>
            <TitleDescription>Please review the summary of your new message before sending it out.</TitleDescription>
          </TitleBlock>
          <HeadActions>
            <Button variant={ButtonTypes.secondary} onClick={viewLogs}>
              View Logs
            </Button>
          </HeadActions>
        </Head>
        {notification && <NotificationStats notification={notification} logs={logs} />}
        <Columns>
          <Column>
            <ColumnTitle>Audience</ColumnTitle>
            <Divider />
            <OptionRow>
              <OptionRowTitle>Location</OptionRowTitle>
              <OptionRowValue>
                {notification?.eventSellerId?.map((el) => locations?.find((l) => l.id === el)?.venueName).join(', ')}
              </OptionRowValue>
            </OptionRow>
            <OptionRow>
              <OptionRowTitle>Ticket Status</OptionRowTitle>
              <OptionRowValue>{notification?.orderStatus?.join(', ')}</OptionRowValue>
            </OptionRow>
            <OptionRow>
              <OptionRowTitle>Ticket Type</OptionRowTitle>
              <OptionRowValue>{notification?.eventTemplateName?.include.join(', ')}</OptionRowValue>
            </OptionRow>
            <OptionRow>
              <OptionRowTitle>Exclude Tickets</OptionRowTitle>
              <OptionRowValue>{notification?.eventTemplateName?.exclude.join(', ')}</OptionRowValue>
            </OptionRow>
            <OptionRow>
              <OptionRowTitle>Notification Mode</OptionRowTitle>
              <OptionRowValue>{notification?.notificationMode?.join(', ')}</OptionRowValue>
            </OptionRow>
            <OptionRow>
              <OptionRowTitle>Total Guests</OptionRowTitle>
              <OptionRowValue>{guests}</OptionRowValue>
            </OptionRow>
            <OptionRow>
              <OptionRowTitle>Total Tickets</OptionRowTitle>
              <OptionRowValue>{tickets}</OptionRowValue>
            </OptionRow>
            <OptionRow>
              <OptionRowTitle>Event Start Date & Time</OptionRowTitle>
              <OptionRowValue>{formatEventDate(notification?.eventStartDate, '')}</OptionRowValue>
            </OptionRow>
            <OptionRow>
              <OptionRowTitle>Event End Date & Time</OptionRowTitle>
              <OptionRowValue>{formatEventDate(notification?.eventEndDate, '')}</OptionRowValue>
            </OptionRow>
            <OptionRow>
              <OptionRowTitle>Message Send Date & Time</OptionRowTitle>
              <OptionRowValue>
                {notification?.scheduledTime
                  ? formatEventDate(notification.eventEndDate, '')
                  : formatEventDate(notification?.createdAt, '')}
              </OptionRowValue>
            </OptionRow>

            {(notification?.allowDateChange ||
              notification?.allowRefund ||
              notification?.skipMessage ||
              notification?.flagReason) && (
              <>
                <Divider />
                {notification?.allowDateChange && (
                  <OptionRow>
                    <OptionRowTitle>Date Change Allowed?</OptionRowTitle>
                    <OptionRowValue>Yes</OptionRowValue>
                  </OptionRow>
                )}
                {notification?.allowRefund && (
                  <OptionRow>
                    <OptionRowTitle>Refund Allowed?</OptionRowTitle>
                    <OptionRowValue>Yes</OptionRowValue>
                  </OptionRow>
                )}
                {notification?.skipMessage && (
                  <OptionRow>
                    <OptionRowTitle>Skip Message</OptionRowTitle>
                    <OptionRowValue>Yes</OptionRowValue>
                  </OptionRow>
                )}
                {notification?.flagReason && (
                  <OptionRow>
                    <OptionRowTitle>Flag Reason</OptionRowTitle>
                    <OptionRowValue>{notification?.flagReason}</OptionRowValue>
                  </OptionRow>
                )}
              </>
            )}
          </Column>

          <Column>
            <ColumnTitle>Message</ColumnTitle>
            <Divider />
            {notification?.subject && (
              <>
                <SubjectWrapper>
                  <OptionRowTitle>Subject</OptionRowTitle>
                </SubjectWrapper>
                <OptionRowValue>{notification.subject}</OptionRowValue>
              </>
            )}
            <MessageWrapper>
              <OptionRowTitle>Message</OptionRowTitle>
            </MessageWrapper>
            <OptionRowValue dangerouslySetInnerHTML={{__html: notification?.message.replaceAll('\n', '<br/>') || ''}} />
          </Column>
        </Columns>
      </Wrapper>
    </PageWrapper>
  );
};

const Wrapper = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 1px 6px 0px #00000029;
  padding: 24px;
`;

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeadActions = styled.div`
  display: flex;
  gap: 12px;
`;

const TitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const Columns = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #e6e7e8;
`;

const Column = styled.div`
  width: 48%;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #cccfd0;
  margin: 8px 0;
`;

const ColumnTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

export const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding: 64px 141px 32px 141px;
  ${Media.down.xxl} {
    padding: 54px 90px 32px 90px;
  }
  ${Media.down.m} {
    padding: 16px;
  }
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 400px;
`;
