import React from 'react';
import {CSVLink} from 'react-csv';
import styled from 'styled-components';

export const CSVButton = ({data, filename}: {data: string[][]; filename?: string}) => {
  return (
    <StyledButton data={data} target="_blank" filename={filename}>
      Download CSV
    </StyledButton>
  );
};

const StyledButton = styled(CSVLink)`
  width: 160px;
  height: 40px;
  border-radius: 40px;
  border: 1px solid #937e6b;
  color: #495258;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  padding-top: 5px;

  &:hover {
    color: #000000;
  }
`;
