import styled from 'styled-components';
import {Media} from '../../../../ui-kit/theme/breakpoints';
import {StaticCard} from '../../../../ui-kit/StaticCard';
import {Button, ButtonTypes, LinkButton} from '../../../../ui-kit/Button';
import {PrimaryTable} from '../../../../ui-kit/Table';
import {Divider, Typography} from 'antd';
import Input from '../../../../ui-kit/Input';
const {Text} = Typography;

export const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding: 64px 141px 32px 141px;
  ${Media.down.xxl} {
    padding: 54px 90px 32px 90px;
  }
  ${Media.down.m} {
    padding: 16px;
  }
`;

export const DashboardHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 25px;
`;

export const LeftCardsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const RightCardsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
`;

export const DashboardCard = styled(StaticCard)`
  margin: 4px 0px 4px 0;
  min-width: 108px;
  box-sizing: border-box;
  border-radius: 6px;
  .ant-typography.ant-typography-secondary {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const CreateButton = styled(LinkButton).attrs({
  type: 'primary',
})``;

export const ManageButton = styled(LinkButton).attrs({
  variant: ButtonTypes.secondary,
})`
  margin-right: 10px;
`;

export const NotificationTable = styled(PrimaryTable)`
  overflow: hidden;
`;

export const MenuAction = styled.div<{$danger?: boolean}>`
  color: ${({theme, $danger}) => (!$danger ? theme.palette.text.primary : theme.palette.common.red)};
`;

export const ModalDescr = styled(Text)`
  font-size: 14px;
  line-height: 22px;
  display: block;
`;

export const AddButton = styled(Button).attrs({
  type: 'primary',
})``;

export const ActionColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const CodeColumn = styled.div``;

export const CodeInput = styled(Input)`
  max-width: 200px;
  height: 32px;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledDivider = styled(Divider)`
  height: 71px;
  border: 1px solid ${({theme}) => theme.palette.common.systemGray};
`;

export const DividerWrapper = styled.div`
  margin-left: 34px;
  margin-right: 50px;
`;
export const SearchWrapper = styled.div`
  padding: 15px 19px;
  background-color: ${({theme}) => theme.palette.common.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  display: flex;
`;

export const SearchInput = styled(Input)`
  border-radius: 4px;
  min-width: 300px;
  height: 36px;
  &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border: 2px solid ${({theme}) => theme.palette.common.brown};
    box-shadow: none;
  }
`;

export const EnterButton = styled(Button)`
  width: 129px;
  height: 36px;
  border-radius: 40px;
  margin-left: 8px;
`;

export const SearchResultWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 14px;
`;

export const ResultLabel = styled.span`
  line-height: 20px;
  color: ${({theme}) => theme.palette.common.black};
`;

export const ClearButton = styled.button`
  border: none;
  background-color: transparent;
  font-weight: bold;
  cursor: pointer;
`;

export const SearchBtn = styled(Button).attrs({
  type: 'text',
})`
  border: none;
  background-color: transparent;
  line-height: 20px;
  font-weight: ${({theme}) => theme.font.weight.bold};
  padding: 0;
  :hover {
    background-color: transparent;
  }
  span > svg {
    color: ${({theme}) => theme.palette.common.black};
    font-size: 20px;
  }
  :active,
  :hover,
  :focus {
    background-color: transparent;
  }
`;
export const PaginationWrapper = styled.div`
  margin: 40px 0px;
  display: flex;
  justify-content: flex-end;
`;

export const StyledTabs = styled.div`
  .ant-tabs-nav {
    margin-bottom: 2px;
  }
  .ant-tabs-tabpane {
    & > div {
      margin-top: 0;
    }
  }
  .ant-tabs-tab {
    padding: 14px 28px 18px 28px;
    margin-left: 0;
    border-radius: 14px 14px 0 0;
    background-color: ${({theme}) => theme.palette.background.gray};
    color: ${({theme}) => theme.palette.text.primary};
  }
  .ant-tabs-tab-active {
    background-color: ${({theme}) => theme.palette.background.lightGold};
    .ant-tabs-tab-btn {
      font-weight: 600;
      color: ${({theme}) => theme.palette.text.primary} !important;
    }
  }
`;
