import React, {useEffect, useState} from 'react';
import {Form as FormAntd, Modal} from 'antd';
import {WarningOutlined} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';
import {ModalLvl} from '../../../types/settings';
import {LinkBack} from '../../../ui-kit/Button/LinkBack';
import {
  GroupTitle,
  MessageFormWrapper,
  LeftSide,
  RightSide,
  CodeFormItem,
  SettingsTitle,
  FormButton,
  ErrorLine,
  ErrorText,
  CodeForm,
  PageWrapper,
  TitleLine,
  ModalDescr,
  SuccessLine,
  SuccessText,
} from './styles';
import Input, {TextArea} from '../../../ui-kit/Input';
import {FormMode, TemplateCreateFields} from '../../../types/messages';
import {useCreateTemplateT} from '../../../hooks/templates';
import {Loader} from '../../../ui-kit/Loader';
import {templateBody} from '../../../queries/types';
import {route} from '../../../constants/routes';
import {ItemLabel} from '../../../ui-kit/Labels';
import {InstructionForVariables} from '../../../containers/Template/InstructionForVariables';

export type TemplateFormProps = {
  formData: useCreateTemplateT;
  mode: FormMode;
  initial?: Partial<templateBody>;
  isLoading?: boolean;
};

const formTitles = {create: 'Create New Message Template', edit: 'Edit Message Template'};
const formButtons = {create: 'Save Template', edit: 'Save Template'};

export const TemplateForm: React.FC<TemplateFormProps> = ({formData, mode, initial, isLoading}) => {
  const {onCreate, error, success, resetResult} = formData;
  const [form] = FormAntd.useForm();
  const title = formTitles[mode];
  const button = formButtons[mode];
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const goManageTemplates = () => navigate(route.manageTemplates.path);

  const [delModalLvl, setDelModalLvl] = useState<ModalLvl>(ModalLvl.closed);
  const setDelPopup = (lvl: ModalLvl) => setDelModalLvl(lvl);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (delModalLvl === ModalLvl.confirm) {
      Modal.confirm({
        title: `Are you sure you want to leave Create new Message?`,
        content: <ModalDescr>You cannot undo this action.</ModalDescr>,
        okText: 'Yes',
        cancelText: 'No',
        autoFocusButton: null,
        icon: <WarningOutlined />,
        onOk: () => setDelPopup(ModalLvl.success),
        onCancel: () => setDelPopup(ModalLvl.closed),
      });
    }
    if (delModalLvl === ModalLvl.success) {
      Modal.success({
        title: ``,
        content: <ModalDescr></ModalDescr>,
        okText: 'Done',
        onOk: () => setDelPopup(ModalLvl.closed),
      });
    }
  }, [delModalLvl]);

  useEffect(() => {
    if (success?.status === 'completed') goManageTemplates();
  }, [success]);

  useEffect(() => {
    if (error) {
      setLoading(false);
    }
  }, [error]);

  const handleChangeForm = () => {
    setLoading(false);
    resetResult?.();
  };

  const onFinish = (values: any) => {
    onCreate?.({...values});
    setLoading(true);
  };

  const onSelectDynamicTemplate = (value: string) => () => {
    const current = form.getFieldValue(TemplateCreateFields.message);
    form.setFieldsValue({[TemplateCreateFields.message]: current + value});
  };

  if (isLoading)
    return (
      <PageWrapper>
        <Loader />
      </PageWrapper>
    );

  return (
    <PageWrapper>
      <TitleLine>
        <LinkBack onClick={goBack}>Back to Manage Templates</LinkBack>
      </TitleLine>
      <CodeForm layout={'vertical'} form={form} onChange={handleChangeForm} onFinish={onFinish}>
        <GroupTitle>{title}</GroupTitle>
        <MessageFormWrapper>
          <LeftSide>
            <CodeFormItem
              label={<ItemLabel label="Message Title*" />}
              rules={[{required: true, message: 'Message Title is required'}]}
              name={[TemplateCreateFields.name]}
              initialValue={initial?.name}>
              <Input />
            </CodeFormItem>
            <CodeFormItem
              label={<ItemLabel label="Message Body*" />}
              rules={[{required: true, message: 'Message Body is required'}]}
              name={[TemplateCreateFields.message]}
              initialValue={initial?.message}>
              <TextArea rows={8} />
            </CodeFormItem>
          </LeftSide>
          <RightSide>
            <SettingsTitle>Instructions on adding dynamic links:</SettingsTitle>
            <InstructionForVariables onSelect={onSelectDynamicTemplate} />
            <ErrorLine>{error && <ErrorText>{error}</ErrorText>}</ErrorLine>
            {success?.status === 'completed' ? (
              <Success mode={mode} textName="Message Template" />
            ) : (
              <FormButton htmlType="submit" type={'primary'} disabled={loading}>
                {button}
              </FormButton>
            )}
          </RightSide>
        </MessageFormWrapper>
      </CodeForm>
    </PageWrapper>
  );
};

export const Success: React.FC<{mode: FormMode; textName: string}> = ({mode, textName}) => {
  return (
    <SuccessLine>
      {mode === 'create' ? (
        <SuccessText>{textName} successfully created</SuccessText>
      ) : (
        <SuccessText>{textName} successfully updated</SuccessText>
      )}
    </SuccessLine>
  );
};
