import React from 'react';
import {LogT} from '../../../../types/messages';
import './modal.css';
import {ColumnsType} from 'antd/lib/table';
import {ModalTable} from '../../../Table/ModalTable';
import {format} from 'date-fns';
import {toZonedTime} from 'date-fns-tz';
import styled from 'styled-components';

type PropsT = {
  data?: LogT[];
};

const columns: ColumnsType<any> = [
  {
    title: 'User',
    key: 'id',
    fixed: 'left',
    render: (value?: LogT) => (
      <div>
        {value?.firstName} {value?.lastName}
      </div>
    ),
  },
  {
    title: 'Order Number',
    key: 'order',
    fixed: 'left',
    render: (value?: LogT) => <div>{value?.ticketureOrderNumber}</div>,
  },
  {
    title: 'Location',
    key: 'location',
    fixed: 'left',
    render: (value?: LogT) => <div>{value?.sellerName}</div>,
  },
  {
    title: 'Event Date',
    key: 'date',
    fixed: 'left',
    render: (value?: LogT) => {
      if (!value?.sellerTimezone || !value?.eventDate) return null;
      return <div>{format(toZonedTime(new Date(value.eventDate), value.sellerTimezone), 'PP')}</div>;
    },
  },
  {
    title: 'SMS',
    key: 'sms',
    fixed: 'left',
    render: (value?: LogT) => {
      if (!value?.phoneNumber) return <div>N/A</div>;
      if (value?.notificationMode === 'sms') return <StatusLabel>{value?.outboundStatus}</StatusLabel>;
      return <div>-</div>;
    },
  },
  {
    title: 'Email',
    key: 'email',
    fixed: 'left',
    render: (value?: LogT) => {
      if (!value?.email) return <div>N/A</div>;
      if (value?.notificationMode === 'email') return <StatusLabel>{value?.outboundStatus}</StatusLabel>;
      return <div>-</div>;
    },
  },
  {
    title: 'Actions',
    key: 'actions',
    fixed: 'left',
    render: (value?: LogT) => (
      <a href={value?.viewTicketLink} target="_blank" rel="noreferrer">
        View
      </a>
    ),
  },
];

export const LogsTable: React.FC<PropsT> = ({data}) => {
  return <ModalTable data={data} columns={columns} />;
};

const StatusLabel = styled.div`
  text-transform: capitalize;
`;
