import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {CreateMessageDesktop} from '../../components/Message/ui/desktop';
import {toInitialMessageState} from '../../helpers/messages';
import {useGetNotification, useUpdateNotification} from '../../hooks/messages';
import {useGetListTemplatesTable, useGetTemplate} from '../../hooks/templates';
// import {isMobile} from '../../ui-kit/theme/breakpoints';
// import {CreateMessageMobile} from '../../components/Message/ui/mobile';
import {route} from '../../constants/routes';

export const EditMessage = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const formData = useUpdateNotification(id);
  const {templates} = useGetListTemplatesTable();
  const {notification, loading: loadingNotification} = useGetNotification(id);
  const {sellerOptions, loading} = formData;
  const initial = toInitialMessageState(notification, sellerOptions);
  const {template, getOne} = useGetTemplate();

  const goDashboard = () => {
    navigate(route.dashboard.path);
  };

  return (
    <CreateMessageDesktop
      mode={'edit'}
      formData={formData}
      templates={templates}
      initial={initial}
      loading={loading || loadingNotification}
      getTemplate={getOne}
      selectedTemplate={template}
      goDashboard={goDashboard}
    />
  );
};
