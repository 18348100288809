import React from 'react';
import {Modal} from 'antd';
import styled from 'styled-components';
import {SendMessageForm} from './SendMessageForm';

type PropsT = {
  modal: boolean;
  setModal: (value: boolean) => void;

  brand?: string;
  notificationMode?: string[];
  //message
  subject?: string;
  messageText?: string;
};

const Title = () => {
  return (
    <TitleBlock>
      <p>Send Test Message</p>
      <p>
        Select the order number that needs to be used for testing and then add the phone or email where this test can be
        sent for validation.
      </p>
    </TitleBlock>
  );
};

export const MessageTesting: React.FC<PropsT> = ({modal, setModal, subject, messageText, brand, ...props}) => {
  const hideModal = () => setModal(false);
  return (
    <Modal title={<Title />} open={modal} onCancel={hideModal} closable footer={null}>
      <MessageWrapper>
        <MessageSubject>{subject}</MessageSubject>
        <MessageText dangerouslySetInnerHTML={{__html: messageText?.replaceAll('/n', '<br/>') || ''}} />
      </MessageWrapper>
      <Divider />
      <SendMessageForm brand={brand} subject={subject} message={messageText} onClose={hideModal} {...props} />
    </Modal>
  );
};

const TitleBlock = styled.div`
  p {
    margin: 0;
    padding: 0;
    &:first-of-type {
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
    }
    &:last-of-type {
      margin-top: 6px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: ${({theme}) => theme.palette.common.darkGray};
    }
  }
`;

const MessageWrapper = styled.div``;
const MessageSubject = styled.p`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: ${({theme}) => theme.palette.common.darkGray};
`;
const MessageText = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: ${({theme}) => theme.palette.common.darkGray};
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #e6e7e8;
`;
