import React from 'react';
import styled from 'styled-components';
import {Table} from 'antd';
import {ColumnsType} from 'antd/lib/table';

type PropsT = {
  data?: any[];
  columns?: ColumnsType<any>;
};

export const ModalTable: React.FC<PropsT> = ({data, columns}) => {
  const _data = data?.map((el, key) => ({...el, key}));
  return <StyledTable dataSource={_data} columns={columns} pagination={false} scroll={{y: 55 * 7}} />;
};

const StyledTable = styled(Table)`
  width: 100%;

  th {
    background-color: transparent !important;
    border: none !important;
    padding: 10px 0 !important;
    &::before {
      display: none;
    }

    color: #495258 !important;
    font-weight: 600 !important;
  }
  .ant-table-cell {
    padding: 10px 2px !important;
    box-sizing: border-box;
    border: none !important;
    &:first-of-type {
      div {
        max-width: 155px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .ant-empty-normal {
    margin: 0 auto;
  }
  a {
    color: #495258 !important;
    text-decoration: underline;
  }

  .ant-table-body {
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background: #ffffff;
    }

    &::-webkit-scrollbar-thumb {
      background: #979797;
      border-radius: 4px;
    }
  }
`;
