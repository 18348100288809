import {format, toZonedTime} from 'date-fns-tz';
import {RecipientT} from '../types/messages';

const formatDate = (date?: string, tz?: string, f = '') => {
  if (!date) return '';
  if (!tz) return '';
  return format(toZonedTime(new Date(date), tz), f);
};

export const recipientsToCSV = (values?: RecipientT[]) => {
  const columns = ['Name', 'Order_Number', 'Location', 'Event_Date', 'Arrival_Time', 'Ticket_URL'];
  const data =
    values?.map((el) => [
      el?.user.firstName + ' ' + el?.user?.lastName,
      el?.ticketureOrderNumber || '',
      el?.sellerName || '',
      formatDate(el?.eventDate, el?.sellerTimezone, 'PP'),
      formatDate(el?.eventDate, el?.sellerTimezone, 'p'),
      '',
    ]) || [];

  return [columns, ...data];
};
