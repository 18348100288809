import React from 'react';
import {LogT, NotificationDetailedT} from '../../../../types/messages';
import styled from 'styled-components';

type PropsT = {
  notification: NotificationDetailedT;
  logs?: LogT[];
};

export const NotificationStats: React.FC<PropsT> = ({notification}) => {
  const guests = (notification?.stats?.email?.totalSent || 0) + (notification?.stats?.sms?.totalSent || 0);

  const stats = notification?.stats;
  const failedEmails = (stats?.email?.totalSent || 0) - (stats?.email?.totalDelivered || 0);
  const failedSms = (stats?.sms?.totalSent || 0) - (stats?.sms?.totalDelivered || 0);
  return (
    <Wrapper>
      <Card>
        <CardTitle>Total Guests</CardTitle>
        <CardValue>
          <Value>{guests}</Value>
        </CardValue>
      </Card>
      <Card>
        <CardTitle>SMS Delivered</CardTitle>
        <CardValue>
          <div>
            <Value $v2>{stats?.sms?.totalDelivered || 0}</Value>
            <Value>/</Value>
            <Value>{stats?.sms?.totalSent || 0}</Value>
          </div>
          {!!failedSms && <Value $v3>{failedSms}</Value>}
        </CardValue>
      </Card>
      <Card>
        <CardTitle>Email Delivered</CardTitle>
        <CardValue>
          <div>
            <Value $v2>{stats?.email?.totalDelivered || 0}</Value>
            <Value>/</Value>
            <Value>{stats?.email?.totalSent || 0}</Value>
          </div>
          {!!failedEmails && <Value $v3>{failedEmails}</Value>}
        </CardValue>
      </Card>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 16px 0 24px 0;
`;

const Card = styled.div`
  width: 32%;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #cccfd0;
`;

const CardTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #8c8c8c;
  margin-bottom: 12px;
`;

const CardValue = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Value = styled.span<{$v1?: boolean; $v2?: boolean; $v3?: boolean}>`
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  padding-left: 3px;
  padding-right: 3px;

  color: ${({$v1, $v2, $v3, theme}) => {
    if ($v1) return theme.palette.common.primaryBlack;
    if ($v2) return theme.palette.common.darkGreen;
    if ($v3) return theme.palette.common.redBrown;
    return theme.palette.common.primaryBlack;
  }};
`;
