import {NotificationStatsT} from './dashboard';

export type FormMode = 'create' | 'edit';

export enum MessageTypes {
  scheduled = 'scheduled',
  sent = 'sent',
  instant = 'instant',
}

export type MessageCreateFormValues = {
  name?: string;
  notificationType?: string;
  eventStartDate?: string;
  eventEndDate?: string;
  scheduledDate?: string;
  scheduledTime?: string;
  notificationMode?: string[];
  guestsInLocation?: string;
  guestsHavingTickets?: string;
  excludeGuestsHavingTickets?: string[];
  orderStatus?: string;
  allowDateChange?: boolean;
  allowRefund?: boolean;
  skipMessaging?: boolean;
  flagReason?: string;
  visitingFromDate?: string;
  visitingFromTime?: string;
  visitingUntilDate?: string;
  visitingUntilTime?: string;
  eventTemplateName?: {
    include: string[];
    exclude: string[];
  };
  subject?: string;
  message?: string;
  description?: string;
  eventSellerId?: string[];
  brand?: string;
};

export enum MessageCreateFields {
  name = 'name',
  notificationType = 'notificationType',
  eventStartDate = 'eventStartDate',
  eventEndDate = 'eventEndDate',
  scheduledDate = 'scheduledDate',
  scheduledTime = 'scheduledTime',
  notificationMode = 'notificationMode',
  guestsInLocation = 'guestsInLocation',
  brand = 'brand',
  guestsHavingTickets = 'guestsHavingTickets',
  excludeGuestsHavingTickets = 'excludeGuestsHavingTickets',
  orderStatus = 'orderStatus',
  allowDateChange = 'allowDateChange',
  allowRefund = 'allowRefund',
  skipMessaging = 'skipMessaging',
  skipMessage = 'skipMessage',
  flagReason = 'flagReason',
  visitingFromDate = 'visitingFromDate',
  visitingFromTime = 'visitingFromTime',
  visitingUntilDate = 'visitingUntilDate',
  visitingUntilTime = 'visitingUntilTime',
  eventTemplateName = 'eventTemplateName',
  message = 'message',
  subject = 'subject',
  description = 'description',
  eventSellerId = 'eventSellerId',
}

export type TemplateCreateFormValues = {
  name?: string;
  message?: string;
};

export enum TemplateCreateFields {
  name = 'name',
  message = 'message',
}

export enum ManageTableFields {
  messageTitle = 'messageTitle',
  status = 'status',
}

export const ManageTableTitles = {
  messageTitle: 'Message Title',
  status: 'Status',
};

export type ManageTableDataT = {
  key?: number;
  messageTitle?: string;
  status?: {id?: string};
};

export type LocationOptions = {
  id: string;
  city: string;
  venueName: string;
};

export type getListLocationOptionsRes = LocationOptions[];

export type TestMessageFields = {
  subject?: string;
  message?: string;
  email?: string;
  phoneNumber?: string;
  ticketureOrderId?: string;
};

export type RecipientT = {
  eventDate?: string;
  id: number;
  metadata?: any;
  sellerId?: string;
  sellerName?: string;
  sellerTimezone?: string;
  ticketureOrderId?: string;
  ticketureOrderNumber?: string;
  totalTickets?: string;
  viewTicketLink?: string;
  redeemed?: boolean;
  user: {id: 9749; firstName: string; lastName: string; email: string; phoneNumber: string};
};

export type NotificationDetailedT = {
  allowDateChange: boolean;
  allowRefund: boolean;
  brand?: string;
  createdAt?: string;
  deletedAt?: string;
  description?: string;
  eventEndDate?: string;
  eventSellerId: string[];
  eventStartDate?: string;
  eventTemplateName: {include: string[]; exclude: string[]};
  flagReason?: string;
  id: number;
  message: string;
  metadata?: any;
  name?: string;
  notificationMode: string[];
  notificationType?: string;
  orderStatus: string[];
  outboundStatus?: string;
  scheduledTime?: string;
  skipMessage: boolean;
  stats: NotificationStatsT;
  subject?: string;
  updatedAt?: string;
  userId: number;
};

export type LogT = {
  id: number;
  userId: number;
  notificationId: number;
  notificationMode: string;
  email: string;
  phoneNumber: string;
  message: string;
  orderId: number;
  ticketId: string;
  externalId: string;
  outboundStatus: string;
  sentAt: string;
  deliveredAt: string;
  metadata?: any;
  createdAt: string;
  updatedAt: string;
  firstName: string;
  lastName: string;
  ticketureOrderNumber: string;
  sellerName: string;
  sellerId: string;
  sellerTimezone: string;
  eventDate: string;
  reason?: string;
  viewTicketLink?: string;
};
