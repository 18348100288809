import React, {useState} from 'react';
import {useLoadNotification} from '../../hooks/templates';
import {ViewNotification} from '../../components/Message/ui/desktop/View';
import {LogsModal} from './Logs';

export const ViewNotificationPage = () => {
  const [modal, setModal] = useState(false);
  const showModal = () => setModal(true);
  const {notification, loading, locations, audience, loadingCSV, logs, downloadCSV} = useLoadNotification();
  return (
    <>
      <LogsModal modal={modal} setModal={setModal} loading={loadingCSV} data={logs} onDownload={downloadCSV} />
      <ViewNotification
        notification={notification}
        locations={locations}
        loading={loading}
        recipients={audience}
        viewLogs={showModal}
        logs={logs}
      />
    </>
  );
};
