import {format} from 'date-fns';
import {ticketStatusTypes} from '../constants/dashboard';
import {removeEmptyKeys} from '../hooks/common';
import {audienceBody, notificationBody} from '../queries/types';
import {SelectOptionT} from '../types/helpers';
import {MessageCreateFields, MessageCreateFormValues, MessageTypes, TestMessageFields} from '../types/messages';
import moment from 'moment';
import {toZonedTime} from 'date-fns-tz';

export const getTableTicketsFilterOptions = (): SelectOptionT[] => {
  return [
    {label: 'Show All', value: 'all'},
    {label: 'Message Scheduled', value: MessageTypes.scheduled},
    {label: 'Message sent', value: MessageTypes.sent},
  ];
};

const toFormattedTime = (date?: string, time?: string) => {
  if (!date || !time) return undefined;
  return `${moment(date).format('YYYY-MM-DD')}T${moment(time).format('HH:mm:ss')}`;
};

export const toCreateMessageData = (values: MessageCreateFormValues): notificationBody => {
  const scheduledDate = toFormattedTime(values?.scheduledDate, values?.scheduledTime);
  const eventStartDate = toFormattedTime(values?.visitingFromDate, values?.visitingFromTime);
  const eventEndDate = toFormattedTime(values?.visitingUntilDate, values?.visitingUntilTime);

  return removeEmptyKeys({
    [MessageCreateFields.name]: values?.name || '',
    [MessageCreateFields.notificationType]: values?.notificationType || '',
    [MessageCreateFields.eventStartDate]: eventStartDate || '',
    [MessageCreateFields.eventEndDate]: eventEndDate || '',
    [MessageCreateFields.scheduledTime]: scheduledDate || '',
    [MessageCreateFields.notificationMode]: values?.notificationMode || [],
    [MessageCreateFields.orderStatus]:
      values?.orderStatus === 'all'
        ? [ticketStatusTypes.redeemed, ticketStatusTypes.unredeemed]
        : values?.orderStatus
        ? [values?.orderStatus]
        : [],
    [MessageCreateFields.allowDateChange]: values?.allowDateChange || false,
    [MessageCreateFields.allowRefund]: values?.allowRefund || false,
    [MessageCreateFields.skipMessaging]: values?.skipMessaging || false,
    [MessageCreateFields.skipMessage]: values?.skipMessaging || false,
    [MessageCreateFields.flagReason]: values?.flagReason || '',
    [MessageCreateFields.subject]: values?.subject || '',
    [MessageCreateFields.eventTemplateName]: {
      include: [values?.guestsHavingTickets || ''],
      exclude: values?.excludeGuestsHavingTickets || [],
    },
    [MessageCreateFields.message]: values?.message || '',
    [MessageCreateFields.eventSellerId]: [values?.eventSellerId] || '',
    [MessageCreateFields.brand]: values?.brand || '',
  }) as notificationBody;
};

export const toUpdateMessageData = (values: MessageCreateFormValues): notificationBody => {
  const scheduledDate = toFormattedTime(values?.scheduledDate, values?.scheduledTime);
  const eventStartDate = toFormattedTime(values?.visitingFromDate, values?.visitingFromTime);
  const eventEndDate = toFormattedTime(values?.visitingUntilDate, values?.visitingUntilTime);
  return removeEmptyKeys({
    [MessageCreateFields.name]: values?.name || '',
    [MessageCreateFields.notificationType]: values?.notificationType || '',
    [MessageCreateFields.eventStartDate]: eventStartDate || '',
    [MessageCreateFields.eventEndDate]: eventEndDate || '',
    [MessageCreateFields.scheduledTime]: scheduledDate || '',
    [MessageCreateFields.notificationMode]: values?.notificationMode || '',
    [MessageCreateFields.allowDateChange]: values?.allowDateChange || false,
    [MessageCreateFields.allowRefund]: values?.allowRefund || false,
    [MessageCreateFields.skipMessaging]: values?.skipMessaging || false,
    [MessageCreateFields.flagReason]: values?.flagReason || '',
    [MessageCreateFields.orderStatus]:
      values?.orderStatus === 'all'
        ? [ticketStatusTypes.redeemed, ticketStatusTypes.unredeemed]
        : [values?.orderStatus] || [],
    [MessageCreateFields.subject]: values?.subject || '',
    [MessageCreateFields.eventTemplateName]: {
      include: values?.guestsHavingTickets || '',
      exclude: values?.excludeGuestsHavingTickets || [],
    },
    [MessageCreateFields.message]: values?.message || '',
    [MessageCreateFields.eventSellerId]: [values?.eventSellerId] || '',
  }) as notificationBody;
};

export const toInitialMessageState = (
  values?: MessageCreateFormValues,
  locationOptions?: any,
): Partial<notificationBody> => {
  const initialLocationValue =
    locationOptions?.find((option: any) => option.id === values?.eventSellerId?.[0])?.id || '';
  return {
    [MessageCreateFields.name]: values?.name,
    [MessageCreateFields.brand]: values?.brand,
    [MessageCreateFields.notificationType]: values?.notificationType,
    [MessageCreateFields.eventStartDate]: values?.eventStartDate,
    [MessageCreateFields.eventEndDate]: values?.eventEndDate,
    [MessageCreateFields.scheduledTime]: values?.scheduledTime,
    [MessageCreateFields.notificationMode]: values?.notificationMode,
    [MessageCreateFields.guestsInLocation]: initialLocationValue,
    [MessageCreateFields.guestsHavingTickets]: values?.guestsHavingTickets,
    [MessageCreateFields.allowDateChange]: values?.allowDateChange,
    [MessageCreateFields.allowRefund]: values?.allowRefund,
    [MessageCreateFields.skipMessaging]: values?.skipMessaging,
    [MessageCreateFields.flagReason]: values?.flagReason,
    [MessageCreateFields.eventTemplateName]: values?.eventTemplateName,
    [MessageCreateFields.subject]: values?.subject,
    [MessageCreateFields.message]: values?.message,
    [MessageCreateFields.orderStatus]: values?.orderStatus,
    [MessageCreateFields.eventSellerId]:
      (values?.eventSellerId?.length || 0) > 1 ? 'all-locations' : values?.eventSellerId?.[0],
  };
};

export const toFindAudience = (values: MessageCreateFormValues): audienceBody => {
  const eventStartDate = toFormattedTime(values?.visitingFromDate, values?.visitingFromTime);
  const eventEndDate = toFormattedTime(values?.visitingUntilDate, values?.visitingUntilTime);

  return removeEmptyKeys({
    [MessageCreateFields.eventStartDate]: eventStartDate || '',
    [MessageCreateFields.eventEndDate]: eventEndDate || '',
    [MessageCreateFields.eventTemplateName]: {
      include: values?.guestsHavingTickets ? [values?.guestsHavingTickets].flat() : [],
      exclude: values?.excludeGuestsHavingTickets || [],
    },
    [MessageCreateFields.eventSellerId]: values?.eventSellerId || '',
    [MessageCreateFields.brand]: values?.brand || '',
    [MessageCreateFields.orderStatus]:
      values?.orderStatus && values?.orderStatus !== 'all' ? [values?.orderStatus] : '',
  }) as audienceBody;
};

export const getErrorResponse = (e: unknown) => {
  const jsonErr = JSON.parse(JSON.stringify(e));
  return jsonErr?.response?.text && JSON.parse(jsonErr?.response?.text);
};

export const formatTestNotification = (values: TestMessageFields): TestMessageFields => {
  return removeEmptyKeys(values);
};

export const formatEventDate = (date?: string, tz?: string, f = 'Pp') => {
  if (!date) return '';
  if (!tz) return format(new Date(date), f);
  return format(toZonedTime(new Date(date), tz), f);
};
