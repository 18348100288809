import React from 'react';
import {LocationOptionsT} from '../../types';
import moment from 'moment';
import {
  PreviewModalTitle,
  TitleDescription,
  OptionRow,
  OptionRowTitle,
  OptionRowValue,
  ErrorLabel,
  SubjectWrapper,
  MessageWrapper,
} from './modalStyles';
import styled from 'styled-components';
import {Button, ButtonTypes} from '../../../../ui-kit/Button';

type PropsT = {
  values: any;
  guests?: number | null;
  numTickets: number | null;
  locations: LocationOptionsT;
  nonValidScheduled?: boolean;

  onBack?: () => void;
  onSubmit?: () => void;
  onTest?: () => void;
  onViewRecipients?: () => void;
};

export const PreviewMessageComponent: React.FC<PropsT> = ({
  values,
  numTickets,
  locations,
  nonValidScheduled,
  guests,
  onBack,
  onSubmit,
  onTest,
  onViewRecipients,
}) => {
  const visitingFrom = `${moment(values?.visitingFromDate).format('ll')} at ${moment(values?.visitingFromTime).format(
    'LT',
  )}`;
  const visitingUntil = `${moment(values?.visitingUntilDate).format('ll')} at ${moment(
    values?.visitingUntilTime,
  ).format('LT')}`;

  const scheduledOn = `${moment(values?.scheduledDate).format('ll')} at ${moment(values?.scheduledTime).format('LT')}`;
  const ticketStatus = values.orderStatus === 'all' ? 'All tickets' : values.orderStatus;
  const excluded = values?.excludeGuestsHavingTickets ? values?.excludeGuestsHavingTickets.join(', ') : 'None';
  return (
    <Wrapper>
      <Head>
        <TitleBlock>
          <PreviewModalTitle>Summary of New Message</PreviewModalTitle>
          <TitleDescription>Please review the summary of your new message before sending it out.</TitleDescription>
        </TitleBlock>
        <HeadActions>
          <Button variant={ButtonTypes.secondary} onClick={onTest}>
            Test Message
          </Button>
          <Button variant={ButtonTypes.secondary} onClick={onViewRecipients}>
            View Recipients
          </Button>
        </HeadActions>
      </Head>
      <Columns>
        <Column>
          <ColumnTitle>Audience</ColumnTitle>
          <Divider />
          <OptionRow>
            <OptionRowTitle>Location</OptionRowTitle>
            <OptionRowValue>
              {locations.find((item) => item.id === values.guestsInLocation)?.label || []}
            </OptionRowValue>
          </OptionRow>
          <OptionRow>
            <OptionRowTitle>Ticket Status</OptionRowTitle>
            <OptionRowValue>{ticketStatus}</OptionRowValue>
          </OptionRow>
          <OptionRow>
            <OptionRowTitle>Exclude Ticket Type</OptionRowTitle>
            <OptionRowValue>{excluded}</OptionRowValue>
          </OptionRow>
          <OptionRow>
            <OptionRowTitle>Ticket Type</OptionRowTitle>
            <OptionRowValue>{values.guestsHavingTickets}</OptionRowValue>
          </OptionRow>
          <OptionRow>
            <OptionRowTitle>Notification Mode</OptionRowTitle>
            <OptionRowValue>{values.notificationMode?.join(', ')}</OptionRowValue>
          </OptionRow>
          <OptionRow>
            <OptionRowTitle>Total Guests</OptionRowTitle>
            <OptionRowValue>{guests}</OptionRowValue>
          </OptionRow>
          <OptionRow>
            <OptionRowTitle>Total Tickets</OptionRowTitle>
            <OptionRowValue>{numTickets || 0}</OptionRowValue>
          </OptionRow>
          <OptionRow>
            <OptionRowTitle>Event Start Date & Time</OptionRowTitle>
            <OptionRowValue>{visitingFrom}</OptionRowValue>
          </OptionRow>
          <OptionRow>
            <OptionRowTitle>Event End Date & Time</OptionRowTitle>
            <OptionRowValue>{visitingUntil}</OptionRowValue>
          </OptionRow>
          {!!scheduledOn && (
            <OptionRow>
              <OptionRowTitle>Message Send Date & Time</OptionRowTitle>
              <OptionRowValue $isError={nonValidScheduled}>
                {scheduledOn}
                {nonValidScheduled && <ErrorLabel>This time in the past</ErrorLabel>}
              </OptionRowValue>
            </OptionRow>
          )}

          {(values.allowDateChange || values.allowRefund || values?.skipMessaging || values?.flagReason) && (
            <>
              <Divider />
              {values.allowDateChange && (
                <OptionRow>
                  <OptionRowTitle>Date Change Allowed?</OptionRowTitle>
                  <OptionRowValue>Yes</OptionRowValue>
                </OptionRow>
              )}
              {values.allowRefund && (
                <OptionRow>
                  <OptionRowTitle>Refund Allowed?</OptionRowTitle>
                  <OptionRowValue>Yes</OptionRowValue>
                </OptionRow>
              )}
              {values?.skipMessaging && (
                <OptionRow>
                  <OptionRowTitle>Skip Message</OptionRowTitle>
                  <OptionRowValue>Yes</OptionRowValue>
                </OptionRow>
              )}
              {values?.flagReason && (
                <OptionRow>
                  <OptionRowTitle>Flag Reason</OptionRowTitle>
                  <OptionRowValue>{values?.flagReason}</OptionRowValue>
                </OptionRow>
              )}
            </>
          )}
        </Column>

        <Column>
          <ColumnTitle>Message</ColumnTitle>
          <Divider />
          {values.subject && (
            <>
              <SubjectWrapper>
                <OptionRowTitle>Subject</OptionRowTitle>
              </SubjectWrapper>
              <OptionRowValue>{values.subject}</OptionRowValue>
            </>
          )}
          <MessageWrapper>
            <OptionRowTitle>Message</OptionRowTitle>
          </MessageWrapper>
          <OptionRowValue>{values.message}</OptionRowValue>
        </Column>
      </Columns>
      <Actions>
        <Button variant={ButtonTypes.secondary} onClick={onBack}>
          Back
        </Button>
        <Button variant={ButtonTypes.primary} onClick={onSubmit}>
          Confirm
        </Button>
      </Actions>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 1px 6px 0px #00000029;
  padding: 24px;
`;

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeadActions = styled.div`
  display: flex;
  gap: 12px;
`;

const TitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const Columns = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #e6e7e8;
`;

const Column = styled.div`
  width: 48%;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #cccfd0;
  margin: 8px 0;
`;

const ColumnTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 22px;
  margin-top: 20px;
`;
