import React, {useState} from 'react';
import {LocationOptionsT} from '../../components/Message/types';
import {PreviewMessageComponent} from '../../components/Message/ui/desktop/PreviewMessage';
import {MessageRecipients} from './MessageRecipients';
import {MessageTesting} from './MessageTesting';
import {RecipientT} from '../../types/messages';

type PreviewModalPropsT = {
  values: any;
  guests?: number | null;
  recipients?: RecipientT[];
  numTickets: number | null;
  locations: LocationOptionsT;
  nonValidScheduled?: boolean;
  onBack?: () => void;
  onSubmit?: () => void;
};

export const PreviewMessage: React.FC<PreviewModalPropsT> = ({...props}) => {
  const [modalTest, setModalTest] = useState(false);
  const [modalRecipients, setModalRecipients] = useState(false);

  const showTestModal = () => setModalTest(true);
  const showRecipientsModal = () => setModalRecipients(true);
  return (
    <>
      <MessageTesting
        modal={modalTest}
        setModal={setModalTest}
        subject={props.values?.subject}
        messageText={props.values?.message}
        brand={props.values?.brand}
        notificationMode={props.values?.notificationMode}
      />
      <MessageRecipients modal={modalRecipients} setModal={setModalRecipients} recipients={props.recipients} />
      <PreviewMessageComponent {...props} onTest={showTestModal} onViewRecipients={showRecipientsModal} />
    </>
  );
};
