import React from 'react';
import {WithChildren} from '../../types/helpers';
import {AppLayout} from '../../ui-kit/AppLayout';
import {Header} from '../../ui-kit/AppLayout/Header';
import {EmptyHeader} from '../../ui-kit/AppLayout/EmptyHeader';
import {ItemType} from 'antd/es/menu/hooks/useItems';
import {useLocation, useNavigate} from 'react-router-dom';
import {route} from '../../constants/routes';
import {useLogOut, useViewer} from '../../hooks/auth';
import {getHeaderLinks} from '../../hooks/common';
import {Footer} from '../../ui-kit/AppLayout/Footer';

export const Layout: React.FC<WithChildren> = ({children}) => {
  const user = useViewer();
  const onLogout = useLogOut();
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const rightText = `Hello, ${user?.firstName || user?.email}`;
  const leftText = 'NOTIFICATIONS PORTAL';
  const ddLinks: ItemType[] = getHeaderLinks().map((el) => ({key: el.key, label: <a href={el.href}>{el.label}</a>}));

  const footer = <Footer />;

  const onSettingsClick = () => navigate(route.settings.path);
  const onGoBack = () => navigate(route.dashboard.path);

  const header = user?.id ? (
    <Header
      onSettingsClick={onSettingsClick}
      onLogout={onLogout}
      goBack={pathname !== route.dashboard.path ? onGoBack : undefined}
      dropDownLinks={ddLinks}
      rightText={rightText}
      leftText={leftText}
    />
  ) : (
    <EmptyHeader />
  );
  return (
    <AppLayout footer={footer} header={header}>
      {children}
    </AppLayout>
  );
};
