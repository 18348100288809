import {Route} from '../helpers/Route';

export enum SearchKey {
  type = 'type',
  from = '',
  to = '',
}

export const route = {
  //redirect route
  redirectToLogin: Route.of({path: '/redirect-to-login-page'}),

  //private routes
  dashboard: Route.of({path: '/'}),
  createNewMessage: Route.of({path: '/create-new-message'}),
  editMessage: Route.of<{id: string}>({path: '/edit-message/:id'}),
  viewMessage: Route.of<{id: string}>({path: '/view-message/:id'}),
  createNewTemplate: Route.of({path: '/create-new-template'}),
  editTemplate: Route.of<{id: string}>({path: '/edit-template/:id'}),
  manageTemplates: Route.of({path: '/manage-templates'}),
  settings: Route.of({path: '/settings'}),
};
