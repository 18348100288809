import React from 'react';
import {RecipientT} from '../../../../types/messages';
import {toZonedTime} from 'date-fns-tz';
import {format} from 'date-fns';
import './modal.css';
import {ColumnsType} from 'antd/lib/table';
import {ModalTable} from '../../../Table/ModalTable';

type PropsT = {
  recipients?: RecipientT[];
};

const columns: ColumnsType<any> = [
  {
    title: 'Name',
    key: 'name',
    fixed: 'left',
    render: (value?: RecipientT) => <div>{value?.user?.firstName + ' ' + value?.user?.lastName}</div>,
  },
  {
    title: 'Order Number',
    key: 'order',
    fixed: 'left',
    render: (value?: RecipientT) => <div>{value?.ticketureOrderNumber}</div>,
  },
  {
    title: 'Redeemed',
    key: 'redeemed',
    fixed: 'left',
    render: (value?: RecipientT) => <div>{value?.redeemed ? 'Yes' : 'No'}</div>,
  },
  {
    title: 'Location',
    key: 'address',
    fixed: 'left',
    render: (value?: RecipientT) => <div>{value?.sellerName}</div>,
  },
  {
    title: 'Event Date',
    key: 'date',
    fixed: 'left',
    render: (value?: RecipientT) => {
      if (!value?.sellerTimezone || !value?.eventDate) return null;
      return <div>{format(toZonedTime(new Date(value.eventDate), value.sellerTimezone), 'PP')}</div>;
    },
  },
  {
    title: 'Arrival Time',
    key: 'time',
    fixed: 'left',
    render: (value?: RecipientT) => {
      if (!value?.sellerTimezone || !value?.eventDate) return null;
      return <div>{format(toZonedTime(new Date(value.eventDate), value.sellerTimezone), 'p')}</div>;
    },
  },
  {
    title: 'Actions',
    key: 'actions',
    fixed: 'left',
    render: (value?: RecipientT) => (
      <a href={value?.viewTicketLink} target="_blank" rel="noreferrer">
        View
      </a>
    ),
  },
];

export const RecipientsTable: React.FC<PropsT> = ({recipients}) => {
  return <ModalTable data={recipients} columns={columns} />;
};
