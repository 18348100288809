import {Select} from 'antd';
import React from 'react';
import styled from 'styled-components';

type PropsT = {
  typeOptions: {
    value: string;
    label: string;
  }[];
  statusOptions: {
    value: string;
    label: string;
  }[];
  onChange: (name: string) => (value?: any) => void;
  filter?: {type?: string; status?: string};
};

export const LogsFilter: React.FC<PropsT> = ({typeOptions, statusOptions, onChange, filter}) => {
  return (
    <FilterWrapper>
      <StyledSelect options={typeOptions} onChange={onChange('type')} placeholder="Type" value={filter?.type} />
      <StyledSelect options={statusOptions} onChange={onChange('status')} placeholder="Status" value={filter?.status} />
    </FilterWrapper>
  );
};

const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledSelect = styled(Select)`
  width: 90px;
`;
