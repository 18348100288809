import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {route} from './constants/routes';
import {RedirectToLogin} from './containers/RedirectToLogin';
import {Dashboard} from './containers/Dashboard';
import {PrivateRoute} from './containers/PrivateRoute';
import {SettingsPage} from './containers/Settings';
import {CreateMessage} from './containers/Message/Create';
import {CreateTemplate} from './containers/Template/Create';
import {Manage} from './containers/Template/Manage';
import {EditMessage} from './containers/Message/Edit';
import {EditTemplate} from './containers/Template/Edit';
import {ViewNotificationPage} from './containers/Message/View';

const PublicRoutes = [
  <Route key="redirect-to-login-page" path={route.redirectToLogin.path} element={<RedirectToLogin />} />,
];

const PrivateRoutes = [
  <Route
    key="dashboard"
    path={route.dashboard.path}
    element={
      <PrivateRoute>
        <Dashboard />
      </PrivateRoute>
    }
  />,
  <Route
    key="create-new-message"
    path={route.createNewMessage.path}
    element={
      <PrivateRoute>
        <CreateMessage />
      </PrivateRoute>
    }
  />,
  <Route
    key="view-message"
    path={route.viewMessage.path}
    element={
      <PrivateRoute>
        <ViewNotificationPage />
      </PrivateRoute>
    }
  />,
  <Route
    key="edit-message"
    path={route.editMessage.path}
    element={
      <PrivateRoute>
        <EditMessage />
      </PrivateRoute>
    }
  />,
  <Route
    key="create-new-template"
    path={route.createNewTemplate.path}
    element={
      <PrivateRoute>
        <CreateTemplate />
      </PrivateRoute>
    }
  />,
  <Route
    key="edit-template"
    path={route.editTemplate.path}
    element={
      <PrivateRoute>
        <EditTemplate />
      </PrivateRoute>
    }
  />,
  <Route
    key="manage-templates"
    path={route.manageTemplates.path}
    element={
      <PrivateRoute>
        <Manage />
      </PrivateRoute>
    }
  />,
  <Route
    key="settings"
    path={route.settings.path}
    element={
      <PrivateRoute>
        <SettingsPage />
      </PrivateRoute>
    }
  />,
];

const RoutesSwitch: React.FC = () => {
  return (
    <Routes>
      {PrivateRoutes}
      {PublicRoutes}
    </Routes>
  );
};

export default RoutesSwitch;
